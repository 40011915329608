.ant-select-selector {
	border-radius: 8px !important;
}

.ql-toolbar {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}
.ql-container {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

