body{
  font-family: Oswald;
}
.contentLayout {
  background-color: #ffffff;
  padding: 50px 16px 0 16px;
}

.layout_page_header {
  padding: 0;
  margin-top: 0.5em;
  line-height: 1;
  margin-bottom: 0.8em;
}

.logo {
  /*width: 100px;*/
  height: 40px;
  position: absolute;
  left: 30px;
  top: 0;
}

.sidebar_toggler {
  width: 100%;
  margin-bottom: 0 !important;
}

.autoWidth_sidebar {
  min-height: calc(100vh - 50px) !important;
  max-width: unset !important;
  flex: 0 0 250px !important;
  min-width: unset !important;
  width: unset !important;
  padding-top: 50px;
}

.autoWidth_sidebar .ant-tree-title {
  display: flex;
}

.sideBarLoader {
  display: block;
  margin: 0 auto;
  padding: 100px 0;
  text-align: center;
  border-radius: 4px;
}

.inner_edit_sidebar {
  height: 32px !important;
  user-select: none;
}

.header_popup_menu {
  top: 50px !important;
  position: fixed;
}

.product_card_p {
  font-size: 14px;
  line-height: 1.5715;
  color: #00000073;
}

.product_tag {
  color: #389e0d;
  background: #f6ffed;
  box-sizing: border-box;
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  height: auto;
  padding: 0 7px;
  white-space: nowrap;
  border: 1px solid  #b7eb8f;
  border-radius: 2px;
  opacity: 1;
  transition: all .3s;
}

.product_type {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}

.product_type:first-letter {
  text-transform: uppercase;
}

.report-style-class {
  /*height: 80vh;*/
  /*height: 100vh;*/
  height: 100%;
}

.ql-editor {
  min-height: 100px;
}

.input_focus:focus {
  border-color: #1890ff !important;
  box-shadow: 0 0 5px rgba(24,114,225,0.5); /* Параметры тени */
}


iframe {
  border: none;
}