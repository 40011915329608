.without_multi_selection .hydrated .as-histogram-widget__wrapper .figure .brush .handle {
    display: none;
}

.hidden_popup_line {
    display: none;
}

body {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none; }

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: rgba(80, 86, 110, 0.9);
}
#form_reg .ant-form-item-explain {
    display: none;
}

.ant-table-tbody {
    background: #fff;
}

.ant-table-row-level-0 {
    background: #fff;
}

#form_reg .ant-input {
    border-bottom: 2px solid #448AFF;
    height: 25px !important;
}

#form_reg .ant-input-number-input {
    /*border-bottom: 1px solid #448AFF;*/
    height: 25px !important;
}

#form_reg .ant-form-item-has-error input{
    border-color: #ff4d4f;
}

/*#form_reg .ant-input :focus{*/
/*  border: none !important;*/
/*  border-bottom: 1px solid #448AFF !important;*/
/*}*/

.ant-input-number-handler {
    display: none !important;
}

.ant-table-cell {
    padding: 5px 10px;
}

.mapboxgl-popup-content {
    min-width: 450px;
    max-height: 350px;
    max-height: fit-content;
    overflow-y: auto;
    /*background-color: rgba(255, 255, 255, 0.7);*/
    background-color: rgba(80, 86, 110, 0.9);
    padding-top: 15px;
    padding-left: 16px;
    padding-right: 12px;
    padding-bottom: 10px;
    border-radius: 4px;
    position: relative;}
.mapboxgl-popup-content div {
    padding-top: 5px;
    width: 100%; }
.mapboxgl-popup-content .mapboxgl-popup-close-button {
    width: 22px;
    height: 22px;
    top: 3px;
    right: 13px;
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    border: none; }
.mapboxgl-popup-content .mapboxgl-popup-close-button:focus {
    border: none;
    outline: none; }
.mapboxgl-popup-content .mapboxgl-popup-close-button:hover {
    background: none;
    color: #fff;
    font-weight: 600; }
.mapboxgl-popup-content h3 {
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 12px;
}
.mapboxgl-popup-content h4 {
    font-size: 18px;
    color: #fff; }
.mapboxgl-popup-content p {
    display: flex;
    margin: 2px 0;
    margin-bottom: 10px; }
.mapboxgl-popup-content p span {
    text-align: left;
    width: 35%;
    color: #fff;}
.mapboxgl-popup-content p span:first-child {
    width: 75%;
    color: #fff;
    font-weight: 400;
    margin-top: 0;
    font-size:  12px;}
.mapboxgl-popup-content p span:last-child {
    width: 25%;
    color: #fff;
    font-weight: 600;
    font-size:  12px;}


/*.mapboxgl-popup-content:before {
  content:  '';
  position: absolute;
  top: 0;
  left: 0;
  width:  100%;
  height: 100%;
  /*backdrop-filter:  blur(1px);*/
/*-webkit-backdrop-filter: blur(10px);*/
/*background-color: rgba(80, 86, 110, 0.9);*/

/*}*/

.mapboxgl-popup-content div {
    position: relative;
}


#map_tab_parent {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none; }
#map_tab_parent ::-webkit-scrollbar {
    display: none;
    width: 0; }

#zid_tab_parent {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none; }
#zid_tab_parent ::-webkit-scrollbar {
    display: none;
    width: 0; }

#map_tab_content_row {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none; }
#map_tab_content_row ::-webkit-scrollbar {
    display: none;
    width: 0; }

#zid_tab_content_row {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none; }
#zid_tab_content_row ::-webkit-scrollbar {
    display: none;
    width: 0; }

#tabsCheck {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none; }
#tabsCheck ::-webkit-scrollbar {
    display: none;
    width: 0; }

.css-2613qy-menu {
    display: none; }

#mobile_column_row {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none; }
#mobile_column_row ::-webkit-scrollbar {
    display: none;
    width: 0; }
#mobile_column_row as-category-widget as-widget-header .as-widget-header__subheader {
    display: none; }

#zid_column_row {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none; }
#zid_column_row ::-webkit-scrollbar {
    display: none;
    width: 0; }
#zid_column_row as-category-widget as-widget-header .as-widget-header__subheader {
    display: none; }

as-category-widget .as-category-widget__list {
    height: 100%; }
as-category-widget .as-category-widget__list .as-category-widget__category .as-category-widget__bar .as-category-widget__bar-value {
    background-color: #448AFF !important; }
as-category-widget .as-category-widget__list .as-category-widget__category .as-category-widget__info .as-category-widget__title {
    font-size: 12px; }
as-category-widget as-widget-header {
    padding-bottom: 10px !important;  }
as-category-widget as-widget-header .as-widget-header__header {
    font-size: 16px;
    font-weight: 700; }
as-category-widget as-widget-header .as-widget-header__subheader {
    margin-top: 0px; margin: 0;}

/*FIXME*/

as-time-series-widget as-histogram-widget as-widget-header {
    padding-top: 20px !important;  }
as-time-series-widget as-histogram-widget as-widget-header .as-widget-header__subheader {
    margin-top: 30px; display: none; }

as-time-series-widget as-histogram-widget .as-histogram-widget__wrapper .figure .brush .handle--wrapper {
    opacity: 0 !important; }
as-time-series-widget as-histogram-widget .as-histogram-widget__wrapper .figure .brush .bottomline {
    opacity: 0 !important; }

as-time-series-widget[time] as-histogram-widget .as-histogram-widget__wrapper .figure .brush .handle--wrapper {
    opacity: 1 !important; }
as-time-series-widget[time] as-histogram-widget .as-histogram-widget__wrapper .figure .brush .bottomline {
    opacity: 1 !important; }

.Mui-disabled {
    cursor: not-allowed !important; }

.as-tooltip {
    z-index: 50; }



.ant-scrolling-effect {
    width: 100% !important;
}

/*Mobile layer changer*/

.css-e56m7-control {
    min-height: 32px !important;
    height: 32px !important;
    border-radius: 8px !important;

}

.css-1vy32w8-control {
    min-height: 32px !important;
    height: 32px !important;
    border-radius: 8px !important;
}

.css-1uccc91-singleValue {
    top:  8px !important;
}

.css-tlfecz-indicatorContainer {
    padding-top:  5px !important;
}


.css-1gtu0rj-indicatorContainer {
    padding-top:  5px !important;
}

.css-1okebmr-indicatorSeparator {
    margin-top: 6px !important;
}

.css-1uccc91-singleValue {
    font-size: 12px !important;
}

.css-i0syzg-menu {
    font-size: 12px !important;
    top:  26px !important;
    border-radius: 8px !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
}



/*Ofd LAYER CHANGER*/
.css-yk16xz-control {
    min-height: 32px !important;
    height: 32px !important;
    border-radius: 8px !important;
}

.css-1pahdxg-control {
    min-height: 32px !important;
    height: 32px !important;
    border-radius: 8px !important;
}

.css-26l3qy-menu {
    font-size: 12px !important;
    top:  26px !important;
    border-radius: 8px !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.css-1uccc91-singleValue {
    font-size: 12px !important;
}


/*ASHYQ LAYER CHANGER*/

.css-yk16xz-control .css-g1d714-ValueContainer .css-1uccc91-singleValue {
    top: 15px !important;
}

.css-1pahdxg-control .css-g1d714-ValueContainer .css-1uccc91-singleValue {
    top: 15px !important;
}

.css-1pahdxg-control {
    border-color: #172044 !important;
    box-shadow: 0 0 0 1px #172044 !important;
}

.css-1pahdxg-control:hover {
    border-color: #172044 !important;
    box-shadow: 0 0 0 1px #172044 !important;
}

.css-1pahdxg-control {
    min-height: 32px !important;
    height: 32px !important;
    border-radius: 8px !important;
}

.select_ant_single {
    width: 180px;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 100;
    margin-top: 5px !important;
    background-color: #fff;
    margin-left: 10px !important;
    padding-top: 2px !important;
    padding-bottom: 1px !important;
}
.select_ant_single .ant-select-selector {
    border: none !important;
    background: none !important;
    height: 24px !important;
    outline: none !important;
    box-shadow: none !important;
}
.select_ant_single .ant-select-selection-item {
    color: #172044 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}
.select_ant_single .ant-select-selector .ant-select-selection-item {
    padding-right: 0;
}
.select_ant_single .ant-select-single {
    height: 24px !important;
}
.select_ant_single .ant-select-selection-item {
    line-height: 23px !important;
}
.ant-select-item-option-content {
    font-size: 12px !important;
}
.ant-select-item-option-selected{
    background-color: #448aff !important;
}
.ant-select-item-option-selected .ant-select-item-option-content {
    color: #fff !important;
    font-weight: 400 !important;
}

.select_ant_map {
    width: 100%;
    padding-top: 3.5px !important;
    padding-bottom: 2.5px !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
}

.figure {
    overflow: initial !important;
}


#users_table {
}
#users_table .ant-table-wrapper {
}
#users_table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
}
#users_table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container table .ant-table-thead {
}
#users_table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container table .ant-table-thead tr .ant-table-cell {
    font-size: 12px !important;

    padding: 5px; }

#users_table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container table .ant-table-thead tr .ant-table-cell .ant-btn {
    font-size: 12px !important;
    height: auto;

    padding: 4px 8px; }

#users_table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container table .ant-table-thead tr .ant-table-cell .ant-table-column-sorters .ant-table-column-sorter {
    margin-left: 0;

}
#users_table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container table .ant-table-tbody .ant-table-row .ant-table-cell {
    font-size: 12px;
    padding: 5px;
    background: #fff;
}



.ant-modal-content {
    border-radius: 4px;
}
.ant-collapse-header {
    background: #fff !important;
}
.ant-select-selection-placeholder {
    font-size: 12px;
}

#filter_modal .ant-select  .ant-select-selector {
    border: none !important;
    border-bottom: 1px solid #bdc3c9 !important;
    background: #f3f4f5 !important;
    box-shadow: none !important;
    border-radius: 4px !important;

}

#filter_modal .ant-select-focused  .ant-select-selector {
    border: none !important;
    border-bottom: 1px solid #172044 !important;
    background: #e6e8ea !important;
    box-shadow: none !important;
    border-radius: 4px !important;
}
#filter_modal .ant-select-selection-item-content {
    font-size: 12px !important;
}
#filter_modal .ant-select-selection-item {
    background: #fff !important;
    border-radius: 4px !important;
}
.ant-collapse-header {
    font-size: 12px;
}
@media (max-width: 1440px) {
    as-time-series-widget as-histogram-widget as-widget-header {
        padding-top: 5px !important;
    }
}

@media(max-width: 1366px){
    .select_ant_single .ant-select-selection-item {
        line-height: 14px !important;
    }
}

@media (max-width: 1450px) {
    #form_reg .ant-row {
        margin-bottom: 5px;
    }

    #form_reg label {
        font-size: 10px !important;
    }

    #form_reg .ant-input {
        height: 20px !important;
        font-size: 12px !important;
        margin-top: 3px !important;
    }
}
