/*Login*/
#auth_login .cardStyle {
	padding: 25px;
	border: none;
	width: 400px;
	margin: 0 auto;
	margin-top: 0px;
	padding-top: 0;
	/*height: 100%;*/
}

#auth_login .cardStyle .ant-card-body {
	padding: 0;
}

#auth_login .login-advice {
	font-size: 14px;
	font-weight: 400;
	margin-top: 20px;
	margin-bottom: 20px;
}
#auth_login .login-button {
	width: 100%;
	margin-top: 30px;
	background-color: #292974;
	border: 1px solid #292974;
	border-radius: 8px;
	padding: 7px;
	height: auto;
}
#auth_login .login-button:hover{
	background-color: #fff;
	color: #292974;
}


.header

#auth_login .ant-form-item-control {
	width: 100%;
	max-width: 100%;
}
#auth_login .ant-form-item-required:before {
	display: none !important;
}

#auth_login .ant-form-item-required:after {
	display: none !important;
}

#auth_login .ant-form-item-label > label {
	font-size: 14px;
	line-height: 16px;
	height: auto;
}
#auth_login .ant-form-item-control {
	margin: 0;
	width: 100%;
}

#auth_login .auth_bottom {
	margin-top: 0px;
	/*position: absolute;*/
	bottom: 50px;
	left: 50%;
	/*transform: translate(-50%, 0);*/
}
#auth_login .flex_row {
	display: flex;
	flex-direction: column;
	width: 69%;
	margin: 0 auto;
	/*margin-top: 300px;*/
	height: auto;
}

#auth_login .graph_2 {
	width: 400px;
	display: block;
	/*margin-top: 60px;*/
	background-color: #fff;
	padding-top: 15px;
	padding-bottom: 15px;
	border-radius: 15px;
	align-self: flex-end;
	margin-right: 0px;
}
#auth_login .graph_2 img {
	width: 100%;
	display: block;
	border-radius: 15px;
}

#auth_login .graph_1 {
	width: 500px;
	display: block;
	background-color: #fff;
	padding-top: 15px;
	padding-bottom: 15px;
	border-radius: 15px;
	margin-top: -150px;
	margin-left: 0px;
}
#auth_login .graph_1 img {
	width: 100%;
	display: block;
}

#auth_login .minititle {
	color: #fff;
	font-size: 32px;
	line-height: 42px;
	font-weight: 700;
	text-align: center;
}
#auth_login .description {
	color: #fff;
	font-size: 16px;
	margin-top: 12px;
	line-height: 22px;
	text-align: center;
}

#auth_login .text {
	width: 480px;
	width: 80%;
	margin: 48px auto 0;
}

/*Reg*/
.row {
	display: flex;
	/*height: 100vh;*/
}
#auth_reg .left {
	display: flex;
	width: 50%;
	height: 100vh;
	flex-direction: column;
	background-color: rgb(255, 255, 255);
	padding-top: 30px;
	position: relative;
}
#auth_reg .right {
	width: 50%;
	height: 100vh;
}
#auth_login .form-title {
	font-size: 28px;
}

/*Left column*/
#auth_reg .regStyle {
	/*padding: 0 35px;*/
	border: none;
	width: 400px;
	margin: 0 auto;
	margin-top: 48px;
}
#auth_reg .form-title {
	text-align: center;
	font-size: 28px;
	font-weight: 700;
	margin: 0px;
	margin-bottom: 24px;
}
#auth_reg .ant-card-body {
	padding: 12px 24px;
}
#auth_reg .ant-row .ant-form-item {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;
	margin-bottom: 6px;
}

#auth_reg .ant-form-item-control {
	width: 100%;
	max-width: 100%;
}
#auth_reg .ant-form-item-required:before {
	display: none !important;
}

#auth_reg .ant-form-item-required:after {
	display: none !important;
}

#auth_reg .ant-form-item-label > label {
	font-size: 12px;
	line-height: 16px;
	height: auto;
}
#auth_reg .ant-form-item-control {
	margin: 0;
}

#auth_reg .bottom {
	margin-top: 0px;
	position: absolute;
	bottom: 50px;
	transform: translate(-50%, 0);
	left: 50%;

}

#auth_reg .ant-form-item-label {
	flex: 0 0 auto;
	max-width: 100%;
	width: 100%;
	text-align: left;
}
#auth_reg .ant-form-item-control-input-content .ant-input {
	width: 100%;
	border-radius: 8px;
	padding: 1px 7px;
	font-size: 14px;
}
#auth_reg #phone {
	width: 100%;
	font-size: 14px;
	border-radius: 8px;
	padding: 1px 7px;
	border: 1px solid rgb(217, 217, 217);
}
#auth_reg .ant-input-affix-wrapper {
	width: 100%;
	border-radius: 8px;
	padding: 1px 7px;
}
#auth_reg #position {
	width: 100%;
	border-radius: 8px;
	padding: 1px 7px;
}
#auth_reg .reg-button-submit {
	width: 100%;
	margin-top: 30px;
	background-color: #292974;
	border: 1px solid #292974;
	border-radius: 8px;
	padding: 7px;
	height: auto;
}
#auth_reg .reg-button-submit:hover {
	background-color: #fff;
	color: #292974;
}
#auth_reg .ant-form-item-explain {
	font-size: 12px;
	min-height: 16px;
}

/*Right column*/
#auth_reg .flex_row {
	display: flex;
	flex-direction: column;
	width: 69%;
	margin: 0 auto;
	/*margin-top: 300px;*/
	height: auto;

}

#auth_reg .graph_2 {
	width: 400px;
	display: block;
	/*margin-top: 60px;*/
	background-color: #fff;
	padding-top: 15px;
	padding-bottom: 15px;
	border-radius: 15px;
	align-self: flex-end;
	margin-right: 0px;
}
#auth_reg .graph_2 img {
	width: 100%;
	display: block;
	border-radius: 15px;

}

#auth_reg .graph_1 {
	width: 500px;
	display: block;
	background-color: #fff;
	padding-top: 15px;
	padding-bottom: 15px;
	border-radius: 15px;
	margin-top: -150px;
	margin-left: 0px;
}
#auth_reg .graph_1 img {
	width: 100%;
	display: block;
}


#auth_reg .minititle {
	color: #fff;
	font-size: 32px;
	line-height: 36px;
	font-weight: 700;
	text-align: center;
}
#auth_reg .description {
	color: #fff;
	font-size: 16px;
	margin-top: 12px;
	line-height: 22px;
	text-align: center;
}

#auth_reg .text {
	/*width: 480px;*/
	width: 80%;
	margin: 48px auto 0;
}

#cabinet_form{
	background: white;
	padding: 15px;
	border-radius: 4px;
	margin-top: 100px;
}

/*#cabinet_form .ant-slider-disabled .ant-slider-track {*/
/*	background: #2ED47A !important;*/
/*}*/

#cabinet_form .ant-slider-disabled{
	cursor: default;
}

#cabinet_form .ant-slider-rail{
	background: #EBEFF2;
}

#cabinet_form .cabinet_slider .ant-slider-track {
	background: linear-gradient(90deg, rgba(0,255,0,1) 0px, rgba(241,255,0,1) 86px, rgba(241,255,0,1) 172px, rgba(255,0,0,1) 260px) !important;
}

@media (max-width: 1450px) and (min-width: 1400px){
	#auth_login .cardStyle {
		margin-top: 36px;
	}
	#auth_login .auth_bottom {
		margin-top: 200px;
	}
	#auth_login .graph_2 {
		margin-top: 96px;
	}
	#auth_login .text {
		margin-top: 96px;
	}
	#auth_reg .regStyle {
		padding: 0 24px;
	}
	#auth_reg .ant-form-item-label > label {
		font-size: 14px;
	}
	#auth_reg .ant-form-item-control-input-content .ant-input {
		font-size: 16px;
	}
	#auth_reg #phone {
		font-size: 16px;
	}
	#auth_reg .ant-form-item-explain {
		font-size: 14px;
	}
	#auth_reg .ant-row .ant-form-item {
		margin-bottom: 10px;
	}
	#auth_reg .bottom {
		margin-top: 24px;
	}
	#auth_reg .graph_2 {
		margin-top: 96px;
		width: 400px;
	}
	#auth_reg .graph_1 {
		width: 500px;
	}
	#auth_reg .right .text {
		margin-top: 96px;
	}
	#auth_reg .minititle {
		font-size: 32px;
		line-height: 42px;
	}
}

@media (max-width: 1370px) {

	#auth_login .form-title {
		font-size: 16px;
	}

	#auth_login .login-advice {
		font-size: 10px;
		text-align: center;

	}

	#auth_login .box p {
		font-size: 12px !important;
		margin-bottom: 10px !important;
	}

	#auth_login .box a {
		font-size: 12px;
		margin-bottom: 10px !important;
		display: block;
	}

	#auth_login .auth_input {
		align-items: center !important;
		margin-bottom: 10px;
	}

	#auth_login .auth_input .ant-form-item-label label{
		font-size: 12px !important;
	}



	#auth_login .auth_input .ant-form-item-control-input input{
		font-size: 12px !important;
		padding: 5px 10px !important;
		line-height: 1 !important;
	}

	#auth_login .auth_input .ant-form-item-control{
		/*font-size: 12px !important;*/
		/*padding: 5px 10px !important;*/
		/*line-height: 1 !important;*/
		width: 60%;
	}


	#auth_login .auth_input .ant-input-password{
		font-size: 12px !important;
		padding: 0px !important;
		line-height: 1 !important;
	}

	#auth_login .login-button {
		width: 60%;
		font-size: 10px;
		padding: 5px;
		margin: 0 auto;
		display: block;
	}



	.header_logo {
		width: 80px;
	}


	#auth_login .auth_bottom {
		margin-top: 0;
	}
	#auth_login .graph_1 {
		width: 350px;
		margin-left: 10px;
	}
	#auth_login .graph_2 {
		width: 280px;
		margin-top: 40px;
	}
	#auth_login .minititle {
		font-size: 24px;
		line-height: 28px;
	}
	#auth_login .description {
		font-size: 14px;
		line-height: 20px;
	}
	#auth_login .text {
		width: 80%;
		margin-top: 48px;
	}

	#auth_reg .row {
		width: 100%;
	}
	#auth_reg .left {
		padding-top: 0px;
	}
	#auth_reg .ant-row .ant-form-item {
		margin-bottom: 6px;
		font-size: 12px;
	}
	#auth_reg .regStyle {
		margin-top: 50px;
		padding: 0 24px;
	}
	#auth_reg .ant-card-body {
		padding-top: 0;
	}

	#auth_reg .box p{
		font-size: 12px;
	}
	#auth_reg .box a{
		font-size: 12px;
	}
	#auth_reg .form-title {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 6px;
		margin-top: 6px;
	}

	/*.flex_box_form {*/

	/*}*/
	#auth_reg .ant-row .ant-form-item {
		width: 47.5%;
		margin-right: 5%;
	}

	#auth_reg .ant-row .ant-form-item:nth-child(2n) {
		margin-right: 0;
	}

	#auth_reg .ant-form-item-label > label {
		font-size: 10px;
	}
	#auth_reg .ant-form-item-control-input-content .ant-input {
		font-size: 10px;
		line-height: 1;
	}

	#auth_reg #phone {
		font-size: 10px;
		line-height: 1;
	}


	#auth_reg .ant-input {
		font-size: 12px;
	}
	#auth_reg .ant-input-affix-wrapper {
		font-size: 12px;
	}
	#auth_reg .ant-form-item-control-input {
		min-height: 20px;
	}
	#auth_reg .ant-form-item-control-input-content {
		max-height: 24px;
		font-size: 12px;
	}
	#auth_reg .ant-form-item-required {
		font-size: 12px;
	}
	#auth_reg .reg-button-submit {
		margin-top: 8px;
		font-size: 10px;
		padding: 3px;
	}
	#auth_reg .ant-form-item-explain {
		font-size: 12px;
		margin-top: 3px;
		min-height: 16px;
		line-height: 1;
	}
	#auth_reg .bottom {
		margin-top: 16px;
		bottom: 30px;
	}

	#auth_reg .bottom p {
		font-size: 10px;
	}

	#auth_reg .graph_1 {
		width: 350px;
		margin-left: 10px;
	}
	#auth_reg .graph_2 {
		width: 280px;
		margin-top: 40px;
	}
	#auth_reg .minititle {
		font-size: 24px;
		line-height: 28px;
	}
	#auth_reg .description {
		font-size: 14px;
		line-height: 20px;
	}
	#auth_reg .text {
		width: 80%;
		margin-top: 48px;
	}

}
