@font-face {
    font-family: 'Oswald';
    src: url('Oswald-SemiBold.eot');
    src: local('Oswald SemiBold'), local('Oswald-SemiBold'),
    url('Oswald-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('Oswald-SemiBold.woff') format('woff'),
    url('Oswald-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('Oswald-ExtraLight.eot');
    src: local('Oswald ExtraLight'), local('Oswald-ExtraLight'),
    url('Oswald-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('Oswald-ExtraLight.woff') format('woff'),
    url('Oswald-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('Oswald-Light.eot');
    src: local('Oswald Light'), local('Oswald-Light'),
    url('Oswald-Light.eot?#iefix') format('embedded-opentype'),
    url('Oswald-Light.woff') format('woff'),
    url('Oswald-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('Oswald-Bold.eot');
    src: local('Oswald Bold'), local('Oswald-Bold'),
    url('Oswald-Bold.eot?#iefix') format('embedded-opentype'),
    url('Oswald-Bold.woff') format('woff'),
    url('Oswald-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('Oswald-Regular.eot');
    src: local('Oswald Regular'), local('Oswald-Regular'),
    url('Oswald-Regular.eot?#iefix') format('embedded-opentype'),
    url('Oswald-Regular.woff') format('woff'),
    url('Oswald-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('Oswald-Medium.eot');
    src: local('Oswald Medium'), local('Oswald-Medium'),
    url('Oswald-Medium.eot?#iefix') format('embedded-opentype'),
    url('Oswald-Medium.woff') format('woff'),
    url('Oswald-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}